@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  body {
    padding-top: 50px;
  }
}
:root {
  --top-menu-height: 4em;
}

.top-menu {
  height: var(--top-menu-height);
}
.grid {
  width: 100%;
  display: grid;
  grid: var(--top-menu-height) / 1fr;
  grid-template-areas:
    'menu'
    'maincontent';
  min-height: 100vh;
}

.menu {
  grid-area: menu;
  z-index: 100;
}

.main-content {
  grid-area: maincontent;
  background-color: #f9fafb;
}

.parent {
  display: inline-flex;
}

.side {
  transition: width 0.3s;
  width: 18em !important;
  overflow: hidden;
  padding-top: var(--top-menu-height);
  z-index: 99;
}

.small-side {
  width: 6em !important;
  flex-basis: 6em;
}

.content {
  position: absolute;
  right: 0;
  transition: all 0.3s;
  padding: 10px;
  width: calc(100% - 18em);
  min-height: 90vh;
}

.small-content {
  padding: 10px;
  width: calc(100% - 6em);
}

.logo-space-menu-item {
  width: 18em;
}

.display-inline {
  display: inline-flex;
  align-items: center;
}

.display-inline-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: 'black';
}

.logo-space {
  font-family: 'Roboto Light', serif;
  font-size: 24px;
  margin: 0 auto;
}

.logo-space img,
.display-inline img {
  margin-right: 8px;
}

.no-border::before {
  display: none;
}
.top-menu i.icon {
  margin: 0 !important;
}

.drop-left-padding {
  padding-left: 0 !important;
}

.label-on-corner {
  top: 0.8em !important;
  left: 78% !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
